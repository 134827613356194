import { Controller } from "@hotwired/stimulus";
let map = null;
let bound = null;
let markers = [];

export default class extends Controller {
  static values = { id: String, state: String };
  connect() {
    L.mapbox.accessToken =
      "pk.eyJ1IjoiZGFuaXNoMjIxMCIsImEiOiJjaXRubWx6bGQwMDB5MnpudzdpdWQ0MXN5In0.7AF1Qau4qkI5JNjlRMB1EQ";
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZGFuaXNoMjIxMCIsImEiOiJjaXRubWx6bGQwMDB5MnpudzdpdWQ0MXN5In0.7AF1Qau4qkI5JNjlRMB1EQ";

    if (this.stateValue === "Louisiana") {
      bounds = [
        [-94.38, 28.14],
        [-88.6, 33.85],
      ];
    } else {
      bounds = [
        [-90.0825, 28.9403],
        [-83.7488, 35.8891],
      ];
    }

    map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/danish2210/ciwxw4l4q000f2qsd8ks8su3p",
      zoom: 4,
      maxBounds: bounds,
    });

    $(".history-panel-hide").click(function () {
      $(".history-panel").animate({ width: "0" }, 500);
    });

    $(document).on("click", ".remove-school", function () {
      //$(".mapboxgl-popup-content").hide();
      map.removeSource("schools");
      map.remove();
      const point_id = $(this).data("id");
      $("#listings").html("");
      $("#removed-listings").html("");
      //$('.point-id-'+point_id).remove();
      $.ajax({ url: "/map/<%=sport.id%>?remove_school=" + point_id });
    });
    $(document).on("click", ".enable-school", function () {
      map.removeSource("schools");
      map.remove();
      const point_id = $(this).data("id");
      $("#listings").html("");
      $("#removed-listings").html("");
      //$('.point-id-'+point_id).remove();
      $.ajax({ url: "/map/<%=sport.id%>?enable_school=" + point_id });
    });
  }

  cards_listener(id) {
    map_history_id = parseInt(id.split("revert-")[1]);
    map.removeSource("schools");
    map.remove();
    $.ajax({ url: "/map/<%=sport.id%>?reset_history=" + map_history_id });
  }

  revertAll() {
    map.removeSource("schools");
    map.remove();
    $.ajax({ url: "/map/<%=sport.id%>?revert_all=true" });
  }

  commitChanges() {
    map.removeSource("schools");
    map.remove();
    $.ajax({ url: "/map/<%=sport.id%>?commit_changes=true" });
  }
}
