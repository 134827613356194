import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

// Connects to
export default class extends Controller {
  connect() {
    this.element.controller = this;
    $(".save-duplicate").click(function () {
      $(".new-group-name").removeClass("has-error");
      const id = $(".duplicate-group-id").val();
      const newName = $(".new-group-name").val();
      if (newName !== "") {
        $(".save-duplicate").prop("disabled", true);
        $(".save-duplicate").html(
          `<div class="spinner-border text-success spinner-border-sm" role="status"></div>`
        );
        const url = "/groups/" + id + "/duplicate/";
        document
          .getElementById("classification-index")
          .controller.postCall(url, newName);
      } else {
        $(".new-group-name").addClass("has-error");
      }
    });
  }

  duplicateModal(e) {
    const name = $(e.target).data("name");
    const id = $(e.target).data("id");
    $(".duplicate-group-id").val(id);
    $(".modal-title").html("Duplicate " + name);
    $("#duplicate-modal").modal("show");
  }

  async postCall(url, newName) {
    const request = new FetchRequest("post", url, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        new_name: newName,
      }),
    });
    const response = await request.perform();
    if (response.ok) {
      window.location = window.location.href;
    }
  }
}
