import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

var token = $('meta[name="csrf-token"]').attr("content");

$.ajaxSetup({
  beforeSend: function (xhr) {
    xhr.setRequestHeader("X-CSRF-Token", token);
  },
});
