import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(".chosen-select")
      .chosen({ disable_search_threshold: 10, width: "300px" })
      .change(function (event) {
        if (event.target == this && $(this).val() != "choose") {
          const organizationId = $(this)
            .find("option:selected")
            .data("organizationId");
          const userId = $(this).data("user-id");
          $.post("/authorized_states/" + $(this).data("user-id"), {
            selected: $(this).val(),
            organization_id: organizationId,
          }).done(function (data) {
            const organizationName = organizationId == 1 ? "AHSAA" : "LHSAA";
            $(`#${userId}-organization`).html(organizationName);
          });
        }
      });
  }

  async toggleAdmin(e) {
    const dis = $(e.target);
    const userId = dis.attr("id");
    const url = dis.data("url");
    let newUrl, role, text;
    if (url == "remove_admin") {
      newUrl = "make_admin";
      role = "User";
      text = "Make Admin";
    } else {
      newUrl = "remove_admin";
      role = "Admin";
      text = "Remove Admin";
    }
    $.post(url, { user_id: userId }, function () {
      $("#current_role-" + userId).text(role);
      dis.text(text);
      dis.data("url", newUrl);
    });
  }
}
