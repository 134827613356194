import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  otherSportChange(e) {
    if ($(e.target).val() == "none") {
      $("#sport_subgroup_count").attr("required", true);
    } else {
      $("#sport_subgroup_count").attr("required", false);
    }
  }
}
