import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { state: String, subGroupName: String, id: String };

  connect() {
    jQuery(document).on(
      "ajax:success",
      "#add-school-form",
      function (event, response, status, xhr) {
        //response
        const sportId = this.idValue;
        $(".add-school-resp").text(response.msg);
        $(".add-school-resp").removeClass("add-school-resp-txt-success");
        $(".add-school-resp").removeClass("add-school-resp-txt-error");
        $(".add-school-resp").addClass(
          "add-school-resp-txt-" + response.status
        );
        if (response.status === "success") {
          $.ajax({ url: `/map/${sportId}` });
        }
      }
    );

    $(document).on("click", ".remove-pdf-lock", function () {
      $(".page-loader").removeClass("LockOn").addClass("LockOff");
    });
  }

  distanceTable(e) {
    const sportId = this.idValue;
    let elm = $(e.target);
    if (elm.data("loaded") == false) {
      elm.data("loaded", true);
      $.ajax({ url: `/sports/${sportId}/distance_table` });
    }
  }
  showHistoryPanel() {
    $(".history-panel").animate({ width: "400" }, 500);
  }

  addSchool() {
    $("#add-school-modal").modal("show");
    $(".chosen-select-school").chosen({ width: "100%" });
    $(".chosen-select-school").trigger("chosen:updated");
  }

  showSwapGroupModal() {
    $("#swap-group-id").modal("show");
  }

  saveGroupId() {
    const sportID = this.idValue;
    const first = $("#first-group-id").val();
    const second = $("#second-group-id").val();
    $(".has-error").removeClass("has-error");
    let error = false;
    $(".required").each(function () {
      if ($(this).val() === "") {
        error = true;
        $(this).addClass("has-error");
      }
    });

    if (error === false) {
      $.post(
        "/swap_group_id",
        { first_id: first, second_id: second, sport_id: sportID },
        function (data) {
          $("#swap-group-id").modal("hide");
          $.ajax({ url: `/map/${sportID}` });
        }
      );
    }
  }

  sortByRegion(e) {
    let ele = e.target;
    let alphabeticallyOrderedDivs;
    var $divs = $("div.school-item");
    if ($(ele).hasClass("asc")) {
      $(ele).addClass("desc");
      $(ele).removeClass("asc");
      alphabeticallyOrderedDivs = $divs.sort(function (a, b) {
        return $(a).find("div").last().data("group_id") >
          $(b).find("div").last().data("group_id")
          ? 1
          : -1;
      });
    } else {
      $(ele).addClass("asc");
      $(ele).removeClass("desc");
      alphabeticallyOrderedDivs = $divs.sort(function (a, b) {
        return $(a).find("div").last().data("group_id") <
          $(b).find("div").last().data("group_id")
          ? 1
          : -1;
      });
    }

    const json = {};
    alphabeticallyOrderedDivs.each(function () {
      const key = $(this).find("div").last().text();
      if (json[key] === undefined) {
        json[key] = [];
      }
      json[key].push($(this));
    });
    let finalAlphabeticallyOrderedDivs = [];
    for (var key in json) {
      if (json.hasOwnProperty(key)) {
        const temp = json[key].sort(function (a, b) {
          return $(a).find("a").text() > $(b).find("a").text() ? 1 : -1;
        });
        finalAlphabeticallyOrderedDivs.push(temp);
      }
    }
    $("#listings").html([].concat.apply([], finalAlphabeticallyOrderedDivs));
  }

  moveToSportViewPage(e) {
    const id = e.target.value;
    if (id != "-1") {
      window.location.href = "/sports/" + id;
    }
  }

  saveMapAsImage(e) {
    this.skmLockScreen(" Saving ...", ".page-loader");
    $(".page-loader").html(
      '<div class="spinner-border text-success spinner-border-sm" role="status"></div>'
    );
    let markersInView = [];
    originalZoom = map.getZoom();
    if (this.stateValue === "Louisiana") {
      map.setMaxBounds([
        [-94.38, 28.14],
        [-88.6, 33.85],
      ]);
    } else {
      map.setMaxBounds([
        [-90.0825, 28.9403],
        [-83.7488, 35.8891],
      ]);
    }
    //if(originalZoom === zoomOnPageLoad){
    //map.setMaxBounds([[-91.0825,28.9403],[-82.9488,35.8891]]);
    map.setZoom(1);
    //}
    const currentMapBounds = map.getBounds();
    var southWest = L.latLng(
        currentMapBounds.getSouthWest().lat,
        currentMapBounds.getSouthWest().lng
      ),
      northEast = L.latLng(
        currentMapBounds.getNorthEast().lat,
        currentMapBounds.getNorthEast().lng
      ),
      mapBoxBounds = L.latLngBounds(southWest, northEast);

    for (i = 0; i < markers.length; i++) {
      //latlng = L.latLng(markers[i].getLngLat().lat, markers[i].getLngLat().lng);
      //if(mapBoxBounds.contains(latlng) === true){
      markersInView.push(markers[i].getElement().id.split("-")[1]);
      //}
    }
    //mapHeight = map.getCanvas().height;
    //if(mapHeight > 1280){
    const mapHeight = 1280;
    //}
    const resolution = 1280 + "x" + mapHeight;
    //map_image_url = "https://api.mapbox.com/styles/v1/danish2210/ciwxw4l4q000f2qsd8ks8su3p/static/"+map.getCenter().lng+","+map.getCenter().lat+","+map.getZoom()+","+map.getBearing()+","+map.getPitch()+"/"+resolution+"?access_token=pk.eyJ1IjoiZGFuaXNoMjIxMCIsImEiOiJjaXRubWx6bGQwMDB5MnpudzdpdWQ0MXN5In0.7AF1Qau4qkI5JNjlRMB1EQ";
    //map_image_url = "https://api.mapbox.com/styles/v1/danish2210/ciwxw4l4q000f2qsd8ks8su3p/static/-87.01565000000001,32.48171836687578,6.786484126013511,"+map.getBearing()+","+map.getPitch()+"/"+resolution+"?access_token=pk.eyJ1IjoiZGFuaXNoMjIxMCIsImEiOiJjaXRubWx6bGQwMDB5MnpudzdpdWQ0MXN5In0.7AF1Qau4qkI5JNjlRMB1EQ";
    const map_image_url =
      "https://api.mapbox.com/styles/v1/danish2210/ciwxw4l4q000f2qsd8ks8su3p/static/-87.01565000000001,32.48171836687578," +
      map.getZoom() +
      "," +
      map.getBearing() +
      "," +
      map.getPitch() +
      "/" +
      resolution +
      "?access_token=pk.eyJ1IjoiZGFuaXNoMjIxMCIsImEiOiJjaXRubWx6bGQwMDB5MnpudzdpdWQ0MXN5In0.7AF1Qau4qkI5JNjlRMB1EQ";

    // The size of the desired map.
    var size = [1280, mapHeight];

    // Create pins at places in the geojson file.
    var pins = [];
    geojson.features.forEach(function (marker) {
      pins.push(
        "pin-m-" +
          marker.group_id +
          "+" +
          marker.properties["marker-color"].replace("#", "") +
          "(" +
          marker.geometry.coordinates.join(",") +
          ")"
      );
    });

    let mapImageUrl =
      "https://api.mapbox.com/styles/v1/danish2210/ciwxw4l4q000f2qsd8ks8su3p/static/" +
      pins.join(",") +
      "/";
    if (this.stateValue === "Louisiana") {
      mapImageUrl += "-91.1008791,30.9475701,"; //'-91.9623,30.9843,'
      mapImageUrl += "7.2/";
    } else {
      mapImageUrl += "-86.51565000000001,32.48171836687578,";
      mapImageUrl += "7.1/";
    }

    mapImageUrl += size.join("x") + "?access_token=" + L.mapbox.accessToken;
    const sportId = this.idValue;
    $.ajax({
      type: "POST",
      dataType: "JSON",
      data: {
        map_image_url: mapImageUrl,
        markers_in_view: markersInView,
        sport_id: sportId,
        resolution: resolution,
      },
      url: "/generate_pdf",
      success: function (data) {
        //skm_LockScreen("", ".page-loader");
        let html =
          "<a download class='btn btn-primary border-white' href='" +
          data.image +
          "'> Download</a> <a style='margin-left: 20px' target='_blank' class='btn btn-primary border-white' href='" +
          data.image +
          "'> View</a> <a style='margin-left: 20px' class='btn btn-primary border-white remove-pdf-lock' href='javascript:void(0)'> Close</a>";
        $(".page-loader").html(html);
        //if(originalZoom === zoomOnPageLoad){
        if (this.stateValue === "Louisiana") {
          map.setMaxBounds([
            [-94.0434, 28.8551],
            [-88.7584, 33.0195],
          ]);
        } else {
          map.setMaxBounds([
            [-90.0825, 28.9403],
            [-83.7488, 35.8891],
          ]);
        }
        //map.setMaxBounds([[-90.0825,28.9403],[-83.7488,35.8891]]);
        map.setZoom(originalZoom);
        //}
      },
    });
    console.log(mapImageUrl);
  }

  skmLockScreen(str, elementId) {
    if ($(elementId).hasClass("LockOn") === true) {
      $(elementId).removeClass("LockOn").addClass("LockOff");
    } else {
      $(elementId).removeClass("LockOff").addClass("LockOn");
    }
  }
}
