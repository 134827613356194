import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let usedClasses = this.element.dataset.usedClasses;
    if (!!usedClasses) usedClasses = JSON.parse(usedClasses);
    if (usedClasses) {
      $(".chosen-select").val(usedClasses).trigger("chosen:updated");
    }
    const height = $(window).height() - 300;
    $(".box-container").height(height + "px");

    // $(".fitler-schools").click(function () {});
    $(".save-filtered-schools").click(function () {
      let list = [];
      $("#container2")
        .find(".box-item")
        .each(function () {
          $(this).addClass("selected-schools");
          if ($(this).is(":visible")) {
            list.push($(this).attr("itemid").split("itm-")[1]);
          }
        });
      const result = Array.from(new Set(list));
      $("#group_school_ids").val(result.join(","));
      $(':input[type="submit"]').prop("disabled", false);
    });
    $(document).on("click", ".search-choice-close", function () {
      $("#group_school_ids").val("");
      $("#container2")
        .find(".box-item")
        .each(function () {
          $(this).appendTo("#container1");
        });
    });
    $(".box-item").draggable({
      cursor: "move",
      helper: "clone",
    });

    $("#container1").droppable({
      drop: function (event, ui) {
        console.log("fdsfa");
        const itemID = ui.draggable.attr("itemid");
        $(".box-item").each(function () {
          if ($(this).attr("itemid") === itemID) {
            $(this).appendTo("#container1");
          }
        });
      },
      tolerance: "pointer",
    });

    $("#container2").droppable({
      drop: function (event, ui) {
        const itemID = ui.draggable.attr("itemid");
        $(".box-item").each(function () {
          if ($(this).attr("itemid") === itemID) {
            if ($(this).is(":visible")) {
              $(this).prependTo("#container2");
            }
          }
        });
      },
      tolerance: "pointer",
    });
    $("#search-schools").keyup(function () {
      let filter = $(this).val();
      let count = 0;
      $(".all-box-container div.current").each(function () {
        if ($(this).text().search(new RegExp(filter, "i")) < 0) {
          $(this).hide();
        } else {
          $(this).show();
          count++;
        }
      });
    });
  }
  filterSchools() {
    const value = $("#other_groups").val();
    if (value === null) {
      alert("Please select classification first");
    } else {
      $(".box-item").removeClass("current").hide();
      value.forEach(function (klass) {
        $(".box-item-" + klass)
          .addClass("current")
          .show();
      });
      $(".box-item").each(function () {
        $(this).appendTo("#container1");
      });
      $(".selected-schools").each(function () {
        $(this).appendTo("#container2");
      });
      $("#school-filter-modal").modal("show");
    }
  }

  removeAllSchools() {
    $("#container2")
      .find(".box-item")
      .each(function () {
        $(this).appendTo("#container1");
      });
  }

  selectAllSchools() {
    $("#container1")
      .find(".box-item:visible")
      .each(function () {
        $(this).appendTo("#container2");
      });
  }
}
