import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  connect() {
    this.element.controller = this;
    $(".source-school").chosen();
    $(".destination-school").chosen();
    $(".compute-mileage-btn").click(function(){
      $(".chosen-single").removeClass("has-error");
      const source = $(".source-school").val();
      const destination = $(".destination-school").val() ;
      if (source === destination){
        $(".chosen-single").addClass("has-error");
        $(".mileage-result").html("");
      }else{
        document.getElementById('school-distance-controller').controller.postCall(source, destination)
      }
    });
  }

  async postCall(source, destination){
    const request = new FetchRequest("post", "/get_distance", {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        destination: destination,
        source: source,
      }),
    });
    const response = await request.perform();
    if (response.ok) {
      const body = await response.text
      const data =  JSON.parse(body)
      $(".mileage-result").html(data.msg).hide().fadeIn('slow');
    }
  }
}

