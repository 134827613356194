// Import and register all your controllers from the importmap under controllers/*
import { application } from "./application";
import ClassificationsController from "./classifications_controller";
import SchoolDistancesController from "./school_distances_controller";
import GroupFormController from "./group_form_controller";
import SportFormController from "./sport_form_controller";
import SportController from "./sport_controller";
import MapController from "./map_controller";
import RoleAssignerController from "./role_assigner_controller";

application.register("classifications", ClassificationsController);
application.register("school-distances", SchoolDistancesController);
application.register("group-form", GroupFormController);
application.register("sport-form", SportFormController);
application.register("sport", SportController);
application.register("map", MapController);
application.register("role-assigner", RoleAssignerController);
