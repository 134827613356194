// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "./libs/jquery";
import "./libs/jquery-ui";
import "@hotwired/turbo-rails";
import "./libs/bootstrap";
import "./controllers";
import "@fortawesome/fontawesome-free/js/all";
import "chosen-js";

Turbo.setConfirmMethod((message, element) => {
  let dialog = $("#turbo-confirm-modal");
  dialog.find(".confirm-message").text(message);
  dialog.modal("show");

  return new Promise((resolve, reject) => {
    dialog.find("button").on("click", function () {
      resolve(this.value == "confirm");
    });
  });
});

$(document).on("turbo:load", () => {
  $(".chosen-select").chosen();
  $('[data-toggle="tooltip"]').tooltip();
});
